import React from "react";
import PropTypes from "prop-types";
import "./DialogBox.css";
import GoalTitle from "../../images/goal_title.png";
import SavedTitle from "../../images/saved_title.png";
class DialogBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        className="dialog-parent"
        onAnimationEnd={this.props.onAnimationEnd}
        style={{
          animation: this.props.showDialogBox ? "pop-up-dialog 2s linear" : "",
        }}
      >
        <div className="dialog-container">
          <div>
            <img
              className="dialog-title"
              src={this.props.goal ? GoalTitle : SavedTitle}
              alt=""
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="dialog-description">得分</div>
            <div
              className="dialog-points"
              style={{ color: this.props.goal ? "#FBFF35" : "#E15235" }}
            >
              {this.props.score}
            </div>
            <div className="dialog-description">分</div>
          </div>
        </div>
      </div>
    );
  }
}

DialogBox.propTypes = {
  score: PropTypes.number,
  goal: PropTypes.bool,
  onAnimationEnd: PropTypes.func,
};

DialogBox.defaultProps = {
  score: 3,
  goal: true,
  onAnimationEnd: () => {
    // console.log("Dialog finished");
  },
};

export default DialogBox;

import React from "react";
import "./GamePage.css";
// import ArrowRight from "../../images/arrow_right.svg";
import Chant from "../sounds/crowd_chant.mp3";
import GoalPost from "../../images/updated/goal_post.png";
import DefaultPose from "../../images/updated/default_pose.png";
import TopLeftPose from "../../images/updated/top_left_caught.png";
import TopRightPose from "../../images/updated/top_right_caught.png";
import BottomLeftPose from "../../images/updated/bottom_left_caught.png";
import BottomRightPose from "../../images/updated/bottom_right_caught.png";
import TopPose from "../../images/updated/top_caught.png";
import TopLeftPoseBall from "../../images/updated/top_left_caught_ball.png";
import TopRightPoseBall from "../../images/updated/top_right_caught_ball.png";
import BottomLeftPoseBall from "../../images/updated/bottom_left_caught_ball.png";
import BottomRightPoseBall from "../../images/updated/bottom_right_caught_ball.png";
import TopPoseBall from "../../images/updated/top_caught_ball.png";
import TopLeftPoseMiss from "../../images/updated/top_left_miss.png";
import TopRightPoseMiss from "../../images/updated/top_right_miss.png";
import BottomLeftPoseMiss from "../../images/updated/bottom_left_miss.png";
import BottomRightPoseMiss from "../../images/updated/bottom_right_miss.png";
import TopPoseMiss from "../../images/updated/top_miss.png";
import WhiteLine from "../../images/updated/white_line.png";
import Ground from "../../images/updated/ground.png";
import Grass from "../../images/grass.svg";
import FrontGrass from "../../images/front_grass.svg";
import Auditorium from "../../images/auditorium.png";
import HitBox from "../../images/hit_box.svg";
import BigCloud from "../../images/cloud_big_2x.png";
import Football from "../../images/ball.png";
import OverlayTitle from "../../images/overlay_title.png";
import Rule1 from "../../images/rule_1.png";
import Rule2 from "../../images/rule_2.png";
import Rule3 from "../../images/rule_3.png";
import Logo from "../../images/atv_logo.png";
import GenericButton from "../widgets/GenericButton";
import LeaderboardIcon from "../../images/leaderboard_icon.svg";
import StartingPose from "../../images/starting_pose.png";
import ArrowIcon from "../../images/start_button_arrow_icon.svg";
import FootballShadow from "../../images/football_shadow.png";
import Leaderboard from "../widgets/Leaderboard";
import DialogBox from "../widgets/DialogBox";
import { playerInfo } from "../utilities/Default";
import Scoreboard from "../widgets/Scoreboard";
import { attemptScore, getUserInfo } from "../utilities/Requests";
import { Oval } from "react-loader-spinner";

class GamePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      kicking: false,
      kickArea: 5,
      preGame: true,
      showRules: false,
      loggedIn: false,
      ballCount: 0,
      collectedToday: 0,
      ballShot: 0,
      goal: false,
      score: 0,
      points: 0,
      showLeaderboard: false,
      showLeaderboardBackButton: true,
      showDialogBox: false,
      loading: true,
      guardedZone: 1,
      goalKeeperInfo: {
        position: 5,
        horizontal: "",
        vertical: "",
        style: "kh-goalkeeper",
      },
      ballMovementInfo: {
        rotation: "",
        translation: "",
      },
      currentPose: DefaultPose,
      playerInfo: playerInfo,
    };
    this.winRate = 0.75;
    this.guardSpots = [1, 2, 3, 4, 6];
    this.goal = false;
    this.pendingTask = null;
    this.kicking = false;
    this.played = false;
    this.audio = new Audio(Chant);
    this.maxPerDay = 30;
  }

  devMode() {
    this.setState({
      loggedIn: true,
      preGame: false,
      showRules: false,
      loading: false,
    });
  }

  componentDidMount() {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      this.devMode();
    } else {
      this.getUserData();
    }
  }

  async getUserData() {
    let result = await getUserInfo();
    if (result.status === "success") {
      this.setState({ loggedIn: true, loading: false });

      this.setState({
        playerInfo: result,
        collectedToday: result.balls_collected_today,
        ballCount: Math.max(
          parseInt(result.balls_collected_today) -
            parseInt(result.balls_played_today),
          0
        ),
        score: parseInt(result.total_balls_scored),
      });
    } else {
      this.setState({ loading: false });
    }
  }

  async sendScoreResult(score) {
    await attemptScore(score);
  }

  goalKeeperWithBallAnimation(kickArea) {
    switch (kickArea) {
      default:
      case 1:
        this.setState({ currentPose: TopLeftPoseBall });
        return 1;
      case 2:
        this.setState({ currentPose: TopPoseBall });
        return 2;
      case 3:
        this.setState({ currentPose: TopRightPoseBall });
        return 3;
      case 4:
        this.setState({ currentPose: BottomLeftPoseBall });
        return 4;
      case 6:
        this.setState({ currentPose: BottomRightPoseBall });
        return 6;
    }
  }

  kickBall(kickArea) {
    if (!this.kicking) {
      this.played = true;
      this.kicking = true;
      this.setState({ kickArea: kickArea }); //param to switch kickArea
      this.determineWinLose(kickArea); //function to do logic to get the correct image position

      this.pendingTask = () => {
        // this.pending is null until image is changed then it will run an anon func
        // which is  ()=> in above here
        this.setState({ kicking: true });
        this.changeAnimation(this.state.currentPose); // once i got the the correct position, i used this to animate it seperately from logic
        this.ballRotation(kickArea);
        this.pendingTask = null;
      };
    }
  }

  showWinLoseDialog() {
    if (this.goal === true) {
      this.setState({
        showDialogBox: true,
        points: 1,
        goal: true,
        ballCount: this.state.ballCount - 1,
        score: this.state.score + 1,
        ballShot: this.state.ballShot + 1,
      });
    } else {
      this.goalKeeperWithBallAnimation(this.state.guardedZone);
      this.setState({
        showDialogBox: true,
        points: 0,
        goal: false,
        ballCount: this.state.ballCount - 1,
      });
    }
  }

  determineWinLose(kickArea) {
    if (this.state.ballCount > 0) {
      if (Math.random() < this.winRate) {
        this.sendScoreResult(1);
        this.goal = true;
        this.audio.currentTime = 0;
        this.audio.play();
        var safeZone = kickArea; //safe area that goalkeeper wont touch
        var guardSpotRemoval = this.guardSpots.indexOf(kickArea); //index of guardspot to remove
        this.guardSpots.splice(guardSpotRemoval, 1);
        // console.log("GOAL"); console.log("current SafeZone is =>", safeZone,
        // this.guardSpots); console.log(   this.changePose(
        // this.guardSpots[Math.floor(Math.random() * this.guardSpots.length)]   ) );
        this.changePose(
          this.guardSpots[Math.floor(Math.random() * this.guardSpots.length)]
        );
        this.guardSpots.push(kickArea);
      } else {
        this.goal = false;
        this.sendScoreResult(0);
        this.setState({
          guardedZone: this.changePose(kickArea),
        });
        // console.log("LOSE"); console.log("All guards =>", this.guardSpots);
        // console.log(this.changePose(kickArea));
        this.changePose(kickArea); // Free play mode;
      }
    } else {
      if (Math.random() < this.winRate) {
        this.goal = true;
        this.audio.currentTime = 0;
        this.audio.play();
        var safeZone = kickArea;
        var guardSpotRemoval = this.guardSpots.indexOf(kickArea);
        this.guardSpots.splice(guardSpotRemoval, 1);
        this.changePose(
          this.guardSpots[Math.floor(Math.random() * this.guardSpots.length)]
        );
        this.guardSpots.push(kickArea);
      } else {
        this.goal = false;
        this.setState({
          guardedZone: this.changePose(kickArea),
        });
        this.changePose(kickArea);
      }
    }
  }

  changePose(num) {
    switch (num) {
      default:
      case 1:
        this.setState({
          currentPose: this.goal !== true ? TopLeftPose : TopLeftPoseMiss,
        });
        return 1;
      case 2:
        this.setState({
          currentPose: this.goal !== true ? TopPose : TopPoseMiss,
        });
        return 2;
      case 3:
        this.setState({
          currentPose: this.goal !== true ? TopRightPose : TopRightPoseMiss,
        });
        return 3;
      case 4:
        this.setState({
          currentPose:
            this.goal !== true ? BottomLeftPose : BottomRightPoseMiss,
        });
        return 4;
      case 6:
        this.setState({
          currentPose:
            this.goal !== true ? BottomRightPose : BottomRightPoseMiss,
        });
        return 6;
    }
  }

  changeAnimation(pose) {
    switch (pose) {
      default:
      case TopLeftPose: //top left
        this.setState({
          goalKeeperInfo: {
            position: 1,
            horizontal: "-50%",
            vertical: "-30%",
            style: "kh-goalkeeper-top-left",
          },
        });
        return 1;
      case TopLeftPoseMiss: //top left
        this.setState({
          goalKeeperInfo: {
            position: 1,
            horizontal: "-50%",
            vertical: "-30%",
            style: "kh-goalkeeper-top-left",
          },
        });
        return 1;

      case TopPose:
        this.setState({
          goalKeeperInfo: {
            position: 2,
            horizontal: "0px",
            vertical: "-40%",
            style: "kh-goalkeeper-top-pose",
          },
        });
        return 2;
      case TopPoseMiss:
        this.setState({
          goalKeeperInfo: {
            position: 2,
            horizontal: "0px",
            vertical: "-40%",
            style: "kh-goalkeeper-top-pose",
          },
        });
        return 2;
      case TopRightPose:
        this.setState({
          goalKeeperInfo: {
            position: 3,
            horizontal: "50%",
            vertical: "-30%",
            style: "kh-goalkeeper-top-right",
          },
        });
        return 3;
      case TopRightPoseMiss:
        this.setState({
          goalKeeperInfo: {
            position: 3,
            horizontal: "50%",
            vertical: "-30%",
            style: "kh-goalkeeper-top-right",
          },
        });
        return 3;
      case BottomLeftPose:
        this.setState({
          goalKeeperInfo: {
            position: 4,
            horizontal: "-50%",
            vertical: "0px",
            style: "kh-goalkeeper-bottom-left",
          },
        }); //bottom left
        return 4;
      case BottomLeftPoseMiss:
        this.setState({
          goalKeeperInfo: {
            position: 4,
            horizontal: "-50%",
            vertical: "0px",
            style: "kh-goalkeeper-bottom-left",
          },
        }); //bottom left
        return 4;
      case DefaultPose:
        this.setState({
          goalKeeperInfo: {
            position: 5,
            horizontal: "0x",
            vertical: "100px",
            style: "kh-goalkeeper",
          },
        }); //bottom center
        return 5;
      case BottomRightPose:
        this.setState({
          goalKeeperInfo: {
            position: 6,
            horizontal: "50%",
            vertical: "0px",
            style: "kh-goalkeeper-bottom-right",
          },
        }); //bottom right
        return 6;
      case BottomRightPoseMiss:
        this.setState({
          goalKeeperInfo: {
            position: 6,
            horizontal: "50%",
            vertical: "0px",
            style: "kh-goalkeeper-bottom-right",
          },
        }); //bottom right
        return 6;
    }
  }

  ballRotation(kickArea) {
    switch (kickArea) {
      default:
      case 1: //top left
        this.setState({
          ballMovementInfo: {
            rotation: "-225deg",
            translation: "-20%",
          },
        });
        return 1;
      case 2: //top center
        this.setState({
          ballMovementInfo: {
            rotation: "-180deg",
            translation: "0%",
          },
        });
        return 2;
      case 3:
        this.setState({
          ballMovementInfo: {
            rotation: "225deg",
            translation: "20%",
          },
        });
        return 3;
      case 4:
        this.setState({
          ballMovementInfo: {
            rotation: "-260deg",
            translation: "0%",
          },
        });
        return 4;
      case 6:
        this.setState({
          ballMovementInfo: {
            rotation: "260deg",
            translation: "0%",
          },
        });
        return 6;
    }
  }

  render() {
    return (
      <div className="game-parent">
        <div
          className="kh-sky"
          style={{
            maxWidth: "1280px",
          }}
        >
          {!this.state.showLeaderboard ? (
            <div
              className="leaderboard-button"
              style={{
                pointerEvents:
                  this.state.kicking || !this.state.loggedIn ? "none" : "auto",
                opacity: !this.state.loggedIn ? 0 : 1,
              }}
            >
              <GenericButton
                label="积分榜"
                icon={LeaderboardIcon}
                height="auto"
                callback={() =>
                  this.setState({
                    showLeaderboard: !this.state.showLeaderboard,
                  })
                }
              />
            </div>
          ) : null}
          {this.state.showLeaderboard ? (
            <Leaderboard
              moreBalls={this.state.collectedToday < this.maxPerDay}
              ballCount={this.state.ballCount}
              backButtonCallback={() =>
                this.setState({
                  showLeaderboard: !this.state.showLeaderboard,
                  ballCount:
                    this.state.ballCount === 0 ? -1 : this.state.ballCount,
                })
              }
              showBackButton={this.state.showLeaderboardBackButton}
              score={this.state.score}
              playerID={this.state.playerInfo.uid}
            />
          ) : null}
          <a target="_blank" rel="noreferrer" href="https://atvnewsonline.com/">
            <img className="atv-logo" src={Logo} alt="" style={{display:"block"}}/>
          </a>

          <Scoreboard
            ballCount={this.state.ballCount}
            name={
              !this.state.loggedIn
                ? "未登入"
                : this.state.playerInfo.display_name
            }
            profilePicture={this.state.playerInfo.avatar_url}
            empty={this.state.preGame ? false : true}
            score={this.state.score}
            ballShot={this.state.ballShot}
          />

          <div
            className="big-cloud"
            style={{
              "--speed": "100s",
              top: 300,
              right: 0,
              filter: "blur(1px)",
              animationDelay: "6s",
            }}
          >
            <img src={BigCloud} alt="" />
          </div>

          <div
            className="big-cloud"
            style={{
              "--speed": "83s",
              top: 200,
              right: 0,
              filter: "blur(1px)",
              animationDelay: "10s",
            }}
          >
            <img
              style={{
                height: 90,
              }}
              src={BigCloud}
              alt=""
            />
          </div>

          <div
            className="big-cloud"
            style={{
              top: 600,
              "--speed": "145s",
              right: 20,
              filter: "blur(2px)",
            }}
          >
            <img
              style={{
                height: 100,
              }}
              src={BigCloud}
              alt=""
            />
          </div>

          <div
            className="big-cloud"
            style={{
              top: 100,
              right: 1000,
              "--speed": "98s",
              filter: "blur(2px)",
            }}
          >
            <img
              style={{
                height: 50,
              }}
              src={BigCloud}
              alt=""
            />
          </div>

          <div
            className="big-cloud"
            style={{
              top: 42,
              "--speed": "87s",
              right: 10,
              filter: "blur(4px)",
              animationDelay: "19s",
            }}
          >
            <img
              style={{
                height: 20,
              }}
              src={BigCloud}
              alt=""
            />
          </div>

          <div
            className="big-cloud"
            style={{
              top: 50,
              right: 525,
              "--speed": "123s",
              filter: "blur(4px)",
            }}
          >
            <img
              style={{
                height: 90,
              }}
              src={BigCloud}
              alt=""
            />
          </div>

          <div
            className="big-cloud"
            style={{
              top: 170,
              right: 305,
              "--speed": "98s",
              filter: "blur(1px)",
            }}
          >
            <img
              style={{
                height: 50,
              }}
              src={BigCloud}
              alt=""
            />
          </div>

          <div
            className="big-cloud"
            style={{
              top: 120,
              right: 115,
              "--speed": "111s",
              filter: "blur(2px)",
              animationDelay: "5s",
            }}
          >
            <img
              style={{
                height: 70,
              }}
              src={BigCloud}
              alt=""
            />
          </div>
          <div className="auditorium-parent">
            <img className="auditorium" src={Auditorium} alt="" />
          </div>

          <div className="white-line-parent">
            <img className="white-line" src={WhiteLine} alt="" />
          </div>

          <div className="kh-turf">
            <img className="kh-grass" src={Ground} alt="" />
            <img className="kh-grass" src={Grass} alt="" />
            <img
              className="kh-grass"
              src={FrontGrass}
              style={{
                zIndex: 120,
              }}
              alt=""
            />
          </div>
          <div className="kh-goalpost-parent">
            <img className="kh-goalpost" src={GoalPost} alt="" />

            <div
              className="hit-box-parent absolute top-left"
              onClick={() => {
                this.kickBall(1);
              }}
              style={{
                opacity: this.state.kicking || this.state.preGame ? 0 : 1,
                pointerEvents:
                  this.state.kicking || this.state.preGame ? "none" : "auto",
                animation:
                  !this.state.kicking && !this.state.preGame
                    ? "hitbox-pop-up 4s infinite"
                    : "none",
                animationDelay:
                  !this.state.kicking && !this.state.preGame ? "0.3s" : "none",
              }}
            >
              <img className="hit-box" src={HitBox} alt="" />
            </div>

            <div
              className="hit-box-parent absolute top-center"
              onClick={() => this.kickBall(2)}
              style={{
                opacity: this.state.kicking || this.state.preGame ? 0 : 1,
                pointerEvents:
                  this.state.kicking || this.state.preGame ? "none" : "auto",
                animation:
                  !this.state.kicking && !this.state.preGame
                    ? "hitbox-pop-up 4s infinite"
                    : "none",
                animationDelay:
                  !this.state.kicking && !this.state.preGame ? "0.6s" : "none",
              }}
            >
              <img className="hit-box" src={HitBox} alt="" />
            </div>

            <div
              className="hit-box-parent absolute top-right"
              onClick={() => this.kickBall(3)}
              style={{
                opacity: this.state.kicking || this.state.preGame ? 0 : 1,
                pointerEvents:
                  this.state.kicking || this.state.preGame ? "none" : "auto",
                animation:
                  !this.state.kicking && !this.state.preGame
                    ? "hitbox-pop-up 4s infinite"
                    : "none",
                animationDelay:
                  !this.state.kicking && !this.state.preGame ? "0.9s" : "none",
              }}
            >
              <img className="hit-box" src={HitBox} alt="" />
            </div>

            <div
              className="hit-box-parent absolute bottom-left"
              onClick={() => this.kickBall(4)}
              style={{
                opacity: this.state.kicking || this.state.preGame ? 0 : 1,
                pointerEvents:
                  this.state.kicking || this.state.preGame ? "none" : "auto",
                animation:
                  !this.state.kicking && !this.state.preGame
                    ? "hitbox-pop-up 4s infinite"
                    : "none",
              }}
            >
              <img className="hit-box" src={HitBox} alt="" />
            </div>

            <div
              className="hit-box-parent absolute bottom-right"
              onClick={() => this.kickBall(6)}
              style={{
                opacity: this.state.kicking || this.state.preGame ? 0 : 1,
                pointerEvents:
                  this.state.kicking || this.state.preGame ? "none" : "auto",
                animation:
                  !this.state.kicking && !this.state.preGame
                    ? "hitbox-pop-up 4s infinite"
                    : "none",
                animationDelay:
                  !this.state.kicking && !this.state.preGame ? "1.2s" : "none",
              }}
            >
              <img className="hit-box" src={HitBox} alt="" />
            </div>
          </div>

          <div className="kh-goalpost-parent">
            <img
              onAnimationEnd={() => {
                this.showWinLoseDialog();
              }}
              onLoad={(e) => {
                if (this.pendingTask !== null) {
                  this.pendingTask();
                }
              }}
              className={this.state.goalKeeperInfo.style}
              src={this.state.currentPose}
              style={{
                opacity: this.state.preGame ? 0 : 1,
                pointerEvents: this.state.preGame ? "none" : "auto",
                animation: this.state.kicking
                  ? "guarding 1s ease 0.2s 1 normal forwards"
                  : "none",
                "--horizontal": this.state.goalKeeperInfo.horizontal,
                "--vertical": this.state.goalKeeperInfo.vertical,
                transform: `translate(${this.state.goalKeeperInfo.horizontal}px ${this.state.goalKeeperInfo.vertical}px)`,
              }}
              alt=""
            />
          </div>

          {!this.state.kicking && !this.state.preGame ? (
            <div className="football-shadow-parent">
              <img
                className="football-shadow"
                src={FootballShadow}
                alt="shadow"
              />
            </div>
          ) : null}

          <div
            className="anchor-parent"
            style={{
              animation: this.state.kicking ? `ball-movement 1s` : "none",
              "--ballOffset": this.state.ballMovementInfo.translation,
              animationDelay: this.state.kicking ? "0.3s" : "none",
              opacity: this.state.preGame ? 0 : 1,
              pointerEvents: "none",
            }}
          >
            <div
              className={
                !this.state.kicking ? "football-anchor" : "football-anchor-kick"
              }
              style={{
                animation: this.state.kicking ? "kick 1s" : "none",
                "--rotation": this.state.ballMovementInfo.rotation,
                animationDelay: this.state.kicking ? "0.3s" : "none",
                animationFillMode: this.state.showDialogBox
                  ? "forwards"
                  : "none",
              }}
            >
              <div className="football-parent">
                <div
                  className="ball-counter"
                  style={{
                    opacity:
                      this.state.kicking || this.state.ballCount < 0 ? 0 : 1,
                  }}
                >
                  {this.state.ballCount}
                </div>
                <img
                  className="football"
                  style={{
                    animation: this.state.kicking ? "shrink-ball 1s" : "none",
                    animationDelay: this.state.kicking ? "0.3s" : "none",
                    animationFillMode: this.state.showDialogBox
                      ? "forwards"
                      : "none",
                  }}
                  src={Football}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            zIndex: 400,
            pointerEvents: "none",
            position: "absolute",
          }}
        >
          <DialogBox
            showDialogBox={this.state.showDialogBox}
            score={this.state.points}
            goal={this.state.goal}
            onAnimationEnd={() => {
              if (this.state.ballCount === 0) {
                if (this.played) {
                  this.setState({
                    showLeaderboard: true,
                    showLeaderboardBackButton: true,
                  });
                  this.getUserData();
                }
              }
              this.setState(
                {
                  showDialogBox: false,
                  goalKeeperInfo: {
                    pose: DefaultPose,
                    position: 5,
                    horizontal: "",
                    vertical: "",
                    style: "kh-goalkeeper",
                    goal: false,
                  },
                  currentPose: DefaultPose,
                },
                () => {
                  this.setState({ kicking: false });
                },
                (this.kicking = false)
              );
            }}
          />
        </div>

        {this.state.preGame ? (
          <div className="starting-pose-parent">
            <img className="starting-pose" src={StartingPose} alt="" />
          </div>
        ) : null}

        <div
          className="start-game-button"
          style={{
            opacity: this.state.showRules || !this.state.preGame ? 0 : 1,
            pointerEvents:
              this.state.showRules || !this.state.preGame ? "none" : "auto",
          }}
        >
          <GenericButton
            callback={
              this.state.loggedIn
                ? () => {
                    this.setState({ showRules: true });
                  }
                : () => {
                    window.open(
                      "https://atvnewsonline.com/wp-login.php?redirect_to=https://atvnewsonline.com/goa" +
                        "l"
                    );
                  }
            }
            label={!this.state.loggedIn ? " 登入" : "立即进入"}
            icon={ArrowIcon}
            secondary={true}
            animation={true}
            fontSize="20px"
            width="170px" // height="40px"
          />
        </div>

        {this.state.showRules ? (
          <div
            className="rules-overlay"
            style={{
              opacity: this.state.showRules ? 1 : 0,
              pointerEvents: this.state.showRules ? "auto" : "none",
              animation: "pop-up 0.5s",
            }}
          >
            <img
              className="rules-title"
              src={OverlayTitle}
              style={{
                marginBottom: 0,
              }}
              alt=""
            />

            <div className="rules-list">
              <img className="rules-description" src={Rule1} alt="" />
              <img className="rules-description" src={Rule2} alt="" />
              <img className="rules-description" src={Rule3} alt="" />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="warning-description-2"
                style={{
                  opacity: this.state.ballCount === 0 ? 1 : 0,
                  animation: this.state.ballCount !== 0 ? "none" : "pop-up 2s",
                  pointerEvents: this.state.ballCount === 0 ? "none" : "auto",
                }}
              >
                *阅读文章以获得高达单日
                <span
                  style={{
                    color: "#E15235",
                    fontSize: "1.5em",
                    fontWeight: "bold",
                    margin: "0em 0.25em",
                  }}
                >
                  30
                </span>
                球的机会
              </div>

              <div
                className="start-button"
                style={{
                  animation:
                    this.state.ballCount !== 0
                      ? "pop-up 2s"
                      : "pop-up2 2s, blinking-border 2s infinite",
                }}
                onClick={
                  this.state.ballCount !== 0
                    ? () => {
                        this.setState({ showRules: false, preGame: false });
                      }
                    : () => {
                        window.open(
                          "https://atvnewsonline.com/goal/latest_post.php"
                        );
                      }
                }
              >
                {this.state.ballCount === 0 ? "阅读文章 获得机会" : "开始游戏"}
                {/* {this.state.ballCount === 0 ? (
                  <img className="start-button-arrow" src={ArrowRight} />
                ) : null} */}
              </div>
            </div>
          </div>
        ) : null}
        {this.state.loading ? (
          <div className="loader-parent-game">
            <div className="loader">
              <Oval
                height={80}
                width={80}
                color="#FFFFFF"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                ariaLabel="oval-loading"
                secondaryColor="#ffffff"
                strokeWidth={5}
                strokeWidthSecondary={5}
              />
            </div>
            <div className="loader-text">载入中</div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default GamePage;

import React from "react";
import PropTypes from "prop-types";
import "./Leaderboard.css";
import Crown from "../../images/crown.png";
import LeaderboardTitle from "../../images/leaderboard_title.png";
import BackButton from "../../images/back_button_icon.png";
import { getLeaderboardList } from "../utilities/Requests";
import { Oval } from "react-loader-spinner";
import { RWebShare } from "react-web-share";
import BackButtonInvert from "../../images/back_icon_hover.png";

class Leaderboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            playerInfo: [],
            selfInfo: [],
            loading: true,

        };
    }

    componentDidMount() {
        this.getLeaderboardData();
        this.getSelfInfo();
    }

    async webShare() {
        try {
            await navigator.share(this.state.shareData);
            console.log("shared")
        } catch (err) {
            console.log("ERROR", err)
        }
    }
    async getLeaderboardData() {
        let result = await getLeaderboardList();
        if (result !== null && result !== undefined) {
            this.setState({
                playerInfo: result,
                loading: false,
            });
        } else {
            if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
                this.setState({
                    playerInfo: [
                        {
                            uid: "1348",
                            display_name: "frozennick",
                            avatar_url:
                                "https://secure.gravatar.com/avatar/38abea0cd3dcca8f3c6dfbc227e86a65?s=96&d=mm&r=" +
                                "g",
                            total_collected: "30",
                            total_played: "30",
                            total_scored: "16",
                            score_rate: "53.33",
                        },
                        {
                            uid: "1459",
                            display_name: "william",
                            avatar_url:
                                "https://secure.gravatar.com/avatar/e665c5c34aeb19f6b9fd6a93ed62d772?s=96&d=mm&r=" +
                                "g",
                            total_collected: "34",
                            total_played: "33",
                            total_scored: "14",
                            score_rate: "42.42",
                        },
                        {
                            uid: "1",
                            display_name: "atvadmin",
                            avatar_url:
                                "https://secure.gravatar.com/avatar/37d014b07c748187c016be00b3c5ae0c?s=96&d=mm&r=" +
                                "g",
                            total_collected: "49",
                            total_played: "31",
                            total_scored: "6",
                            score_rate: "19.35",
                        },
                        {
                            uid: "2",
                            display_name: "亚视新闻",
                            avatar_url:
                                "https://atvoss.oss-cn-hongkong.aliyuncs.com/wp-content/uploads/2021/09/759718163" +
                                "1536180338583.jpeg",
                            total_collected: "22",
                            total_played: "14",
                            total_scored: "3",
                            score_rate: "21.43",
                        },
                        {
                            uid: "1458",
                            display_name: "karhoong92",
                            avatar_url:
                                "https://secure.gravatar.com/avatar/1dcb0b5f9af0860be8bf004d5331940e?s=96&d=mm&r=" +
                                "g",
                            total_collected: "4",
                            total_played: "0",
                            total_scored: "0",
                            score_rate: "nan",
                        },
                        ,
                        {
                            uid: "1458",
                            display_name: "karhoong92",
                            avatar_url:
                                "https://secure.gravatar.com/avatar/1dcb0b5f9af0860be8bf004d5331940e?s=96&d=mm&r=" +
                                "g",
                            total_collected: "4",
                            total_played: "0",
                            total_scored: "0",
                            score_rate: "nan",
                        },
                        {
                            uid: "1458",
                            display_name: "karhoong92",
                            avatar_url:
                                "https://secure.gravatar.com/avatar/1dcb0b5f9af0860be8bf004d5331940e?s=96&d=mm&r=" +
                                "g",
                            total_collected: "4",
                            total_played: "0",
                            total_scored: "0",
                            score_rate: "nan",
                        },
                        ,
                        {
                            uid: "1458",
                            display_name: "karhoong92",
                            avatar_url:
                                "https://secure.gravatar.com/avatar/1dcb0b5f9af0860be8bf004d5331940e?s=96&d=mm&r=" +
                                "g",
                            total_collected: "4",
                            total_played: "0",
                            total_scored: "0",
                            score_rate: "nan",
                        },
                        {
                            uid: "1458",
                            display_name: "karhoong92",
                            avatar_url:
                                "https://secure.gravatar.com/avatar/1dcb0b5f9af0860be8bf004d5331940e?s=96&d=mm&r=" +
                                "g",
                            total_collected: "4",
                            total_played: "0",
                            total_scored: "0",
                            score_rate: "nan",
                        },
                        ,
                        {
                            uid: "1458",
                            display_name: "karhoong92",
                            avatar_url:
                                "https://secure.gravatar.com/avatar/1dcb0b5f9af0860be8bf004d5331940e?s=96&d=mm&r=" +
                                "g",
                            total_collected: "4",
                            total_played: "0",
                            total_scored: "0",
                            score_rate: "nan",
                        },
                        ,
                        {
                            uid: "1458",
                            display_name: "karhoong92",
                            avatar_url:
                                "https://secure.gravatar.com/avatar/1dcb0b5f9af0860be8bf004d5331940e?s=96&d=mm&r=" +
                                "g",
                            total_collected: "4",
                            total_played: "0",
                            total_scored: "0",
                            score_rate: "nan",
                        },
                    ],
                    playerID: 1,
                    loading: false,
                });
            }
        }
    }

    checkForPlayer() {
        for (let i = 0; i < this.state.playerInfo.length; i++) {
            if (parseInt(this.state.playerInfo[i].uid) === this.props.playerID) {
                return true;
            }
        }
    }

    getSelfInfo() {
        for (let i = 0; i < this.state.playerInfo.length; i++) {
            if (parseInt(this.state.playerInfo[i].uid) === this.props.playerID) {
                this.setState({ selfInfo: this.state.playerInfo[i] });
            }
        }
    }

    render() {
        return (
            <div className="leaderboard-parent">
                <div className="leaderboard-title">
                    <img
                        style={{
                            height: 64,
                            padding: "1.5em",
                        }}
                        src={LeaderboardTitle}
                        alt=""
                    />
                </div>
                <div className="back-button" onClick={this.props.backButtonCallback}>
                    <a>
                    <img
                        style={{
                            height: 42,
                            padding: "2.5em",
                        
                        }}
                        src={BackButton}
                        alt=""
                    />

                    <img
                        style={{
                            height: 42,
                            padding: "2.5em",
                        }}
                        src={BackButtonInvert}
                        alt=""
                    />
                    </a>
                </div>
                {this.props.ballCount > 0 ? null : (
                    <div className="congrats-parent">
                        <div>恭喜你参与并完成了此轮Goal力射球赛</div>
                        {this.props.moreBalls ? (
                            <div>点击下方按钮以获取更多可玩球数</div>
                        ) : (
                            <div style={{ marginTop: "1.5em" }}>
                                <div>今日所获的的球数已达到顶限</div>
                                <div style={{ fontSize: "1.5em" }}>我们明天再见！</div>
                            </div>
                        )}
                        {this.props.moreBalls ? (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <div
                                    className="start-button"
                                    style={{
                                        animation: "pop-up2 2s, blinking-border 2s infinite",
                                        fontSize: "18px",
                                    }}
                                    onClick={() => {
                                        window.open("https://atvnewsonline.com/goal/latest_post.php");
                                    }}
                                >
                                    阅读文章 获得机会
                                </div>

                                <RWebShare
                                    data={{
                                        text: "一起来挑战成为最佳得分球员，每周都有丰富的奖金等着你，得分越高，奖金越多",
                                        url: "https://atvnewsonline.com/goal/",
                                        title: "全球追Goal力",
                                    }}
                                   
                                >
                                    <div className="share-button">
                                        分享
                                    </div>
                                </RWebShare>
                            </div>

                        ) : 
                            <RWebShare
                                    data={{
                                        text: "一起来挑战成为最佳得分球员，每周都有丰富的奖金等着你，得分越高，奖金越多",
                                        url: "https://atvnewsonline.com/goal/",
                                        title: "全球追Goal力",
                                    }}
                                   
                                >
                                    <div className="share-button">
                                        分享
                                    </div>
                                </RWebShare>
                        
                        }


                    </div>
                )}
                {this.state.loading ? (
                    <div className="loader-parent">
                        <div className="loader">
                            <Oval
                                height={80}
                                width={80}
                                color="#FFFFFF"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="oval-loading"
                                secondaryColor="#ffffff"
                                strokeWidth={5}
                                strokeWidthSecondary={5}
                            />
                        </div>
                        <div className="loader-text">载入中</div>
                    </div>
                ) : (
                    <div
                        className="leaderboard-list"
                        style={{
                            height:
                                this.props.ballCount > 0
                                    ? "calc(100% - 128px)"
                                    : "calc(100% - 296px)",
                        }}
                    >
                        {this.state.playerInfo
                            .sort((a, b) => b.points - a.points)
                            .map((player, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={
                                            parseInt(player.uid) !== this.props.playerID
                                                ? "player-info-leaderboard"
                                                : "player-info-self"
                                        }
                                        style={{
                                            paddingTop: index === 0 ? "2em" : "0.75em",
                                        }}
                                    >
                                        <div
                                            className={
                                                parseInt(player.uid) !== this.props.playerID
                                                    ? "player-placement"
                                                    : "player-placement-self"
                                            }
                                        >
                                            {index === 0 ? (
                                                <img
                                                    src={Crown}
                                                    style={{
                                                        height: "100%",
                                                        width: "100%",
                                                    }}
                                                    alt=""
                                                />
                                            ) : (
                                                index + 1
                                            )}
                                        </div>

                                        <div>
                                            <img
                                                style={{
                                                    objectFit: "contain",
                                                    borderRadius: "50%",
                                                    width: 64,
                                                    height: 64,
                                                    border: parseInt(player.uid) == this.props.playerID?"2px solid #FFFFFF":"2px solid #E1523580",
                                                }}
                                                className={
                                                    parseInt(player.uid) !== this.props.playerID
                                                        ? "player-profile-picture-leaderboard"
                                                        : "player-profile-picture-self"
                                                }
                                                src={player.avatar_url}
                                                alt=""
                                            />
                                        </div>

                                        <div
                                            className={
                                                parseInt(player.uid) !== this.props.playerID
                                                    ? "player-name-leaderboard"
                                                    : "player-name-self"
                                            }
                                        >
                                            {player.display_name}
                                        </div>

                                        <div
                                            className={
                                                parseInt(player.uid) !== this.props.playerID
                                                    ? "player-score-leaderboard"
                                                    : "player-score-self"
                                            }
                                        >
                                            {player.total_scored}分
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}

                {/* {this.checkForPlayer(this.state.playerID) !== true ?
                    <div className="player-info-leaderboard"
                        style={{
                            bottom: 0,
                            position: "absolute",
                            background: "#E15235",
                            width: "100%",
                            maxWidth: "512px",
                            paddingLeft: 0,
                            paddingRight: 0,
                            opacity: this.state.loading ? 0 : 1
                        }}>
                        <div className="player-placement-leaderboard"
                            style={{ color: "white", marginLeft: "1.5em" }}>
                            3
                        </div>

                        <div>
                            <img className="player-profile-picture-leaderboard"
                                style={{
                                    objectFit: "contain",
                                    borderRadius: "50%",
                                    width: "65px",
                                    height: "65px",
                                    border: "2px solid #FFFFFF"
                                }}
                                src={this.state.selfInfo.avatar_url}
                                alt=""
                            />
                        </div>

                        <div className="player-name-leaderboard"
                            style={{ color: "white" }}>
                            {this.state.selfInfo.display_name}
                        </div>

                        <div className="player-score-leaderboard"
                            style={{ color: "white", marginRight: "2em" }}>
                            {this.state.selfInfo.total_scored} 分
                        </div>

                    </div> : null
                } */}
            </div>
        );
    }
}

Leaderboard.propTypes = {
    backButtonCallback: PropTypes.func,
    showBackButton: PropTypes.func,
    score: PropTypes.number,
    playerID: PropTypes.number,
};

Leaderboard.defaultProps = {
    showBackButton: true,
};

export default Leaderboard;

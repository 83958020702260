import React from "react";
import ScoreBoard from "../../images/scoreboard.png";
import ScoreBoard_v2 from "../../images/scoreboard_empty.png";
import GoalLogo from "../../images/atv_goal_logo.png";
import "./Scoreboard.css";
import PropTypes from "prop-types";

class Scoreboard extends React.Component {
  render() {
    return (
      <div className="scoreboard-parent">
        <div className="image-container">
          <img
            className="scoreboard"
            src={!this.props.empty ? ScoreBoard : ScoreBoard_v2}
            alt=""
          />
          {this.props.empty ? (
            <div className="player-info-kh">
              <div className="flex-hori ">
                <img
                  src={GoalLogo}
                  alt="logo"
                  className="full-size-proper-image"
                />
                <div className="flex-hori" style={{ marginLeft: "0em" }}>
                  {/* <img
                    src={this.props.profilePicture}
                    alt="profile"
                    className="scoreboard-profile-picture"
                  /> */}
                  <div className="participant">
                    <div className="participant-title">参赛者</div>
                    <div className="participant-name">
                      {this.props.name.length < 12
                        ? this.props.name
                        : this.props.name.slice(0, 12) + ".."}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {this.props.empty ? (
            this.props.ballCount >= 0 ? (
              <div className="score-info-kh">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="score-title">目前得分</div>
                  <div className="black-container">
                    <div className="balls-shot">{this.props.ballShot}</div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "1em",
                  }}
                >
                  <div className="score-title">本周累计分数</div>
                  <div className="black-container">
                    <div className="score">{this.props.score}</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="score-info-kh">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="score-title">训练模式</div>
                </div>
              </div>
            )
          ) : null}
        </div>
      </div>
    );
  }
}

Scoreboard.propTypes = {
  ballCount: PropTypes.number,
  name: PropTypes.string,
  profilePicture: PropTypes.string,
  score: PropTypes.number,
  ballShot: PropTypes.number,
  empty: PropTypes.bool,
};

Scoreboard.defaultProps = {
  empty: false,
  ballCount: 0,
};

export default Scoreboard;


import axios from 'axios';
import APIPaths from '../configs/APIPaths';
import APIResult from '../configs/APIResult';

export function debugPrint(e) {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
        console.log(e);
    }
}

export async function callAxiosAPI(body, target, defaultReturn) {
    debugPrint(target);
    debugPrint(body);

    var proceed = true;
    Object
        .values(body)
        .forEach(param => {
            if (param === null || param === undefined) {
                proceed = false;
            }
        });

    if (proceed) {
        await new Promise(resolve => setTimeout(resolve, 500));
        return await axios
            .post(target, body, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
            }
        })
            .then((res) => {
                debugPrint(res["data"])
                return res["data"]
            })
            .catch((err) => {
                if(err !== undefined)
                {
                    debugPrint(err);
                    if(err.response.status === 401)
                    {
                        window.location.reload();
                    }
                }
            });
    } else {
        return defaultReturn;
    }
}


export async function getUserInfo() {
    var formData = new FormData();
    formData.set("action","get_user_ball_today");

    var body = formData;
    var target = APIPaths.apiEndPoint;

    return callAxiosAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: []
    })
}

export async function getLeaderboardList() {
    var formData = new FormData();
    formData.set("action","get_score_ranking");
    
    var body = formData;
    var target = APIPaths.apiEndPoint;

    return callAxiosAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: []
    })
}

export async function attemptScore(result) {
    var formData = new FormData();
    formData.set("action","attempt_score");
    formData.set("success_or_fail",result);
    
    var body = formData;
    var target = APIPaths.apiEndPoint;

    return callAxiosAPI(body, target, {
        status: APIResult.TIME_OUT,
        status_message: "-",
        t: []
    })
}
export const playerInfo = {
  status: "success",
  uid: 1,
  max_daily_available: 30,
  avatar_url:
    "https://secure.gravatar.com/avatar/37d014b07c748187c016be00b3c5ae0c?s=96&d=mm&r=g",
  display_name: "-",
  balls_collected_today: "0",
  balls_played_today: "0",
  balls_scored_today: "0",
  total_balls_collected: "0",
  total_balls_played: "0",
  total_balls_scored: "0",
};

export const leaderboardData = [
  {
    uid: "1",
    display_name: "atvadmin",
    avatar_url:
      "https://secure.gravatar.com/avatar/37d014b07c748187c016be00b3c5ae0c?s=96&d=mm&r=g",
    total_collected: "33",
    total_played: "17",
    total_scored: "4",
    score_rate: "23.53",
  },
  {
    uid: "2",
    display_name: "亚视新闻",
    avatar_url:
      "https://atvoss.oss-cn-hongkong.aliyuncs.com/wp-content/uploads/2021/09/7597181631536180338583.jpeg",
    total_collected: "22",
    total_played: "14",
    total_scored: "3",
    score_rate: "21.43",
  },
];

export const playerData = [
  {
    name: "Miss Del Icious",
    profilePicture: "",
    points: 6969,
    playerID: 1,
  },
];

import React from "react";
import PropTypes from "prop-types";
import "./GenericButton.css"
class GenericButton extends React.Component {
    render() {
        return (
            <div className="button-parent"
                onClick={this.props.callback}
                style={{
                    width: this.props.width,
                    height: this.props.height
                }}>
                <div style={{ display: "flex", flexDirection: this.props.secondary ? "row-reverse" : "row", justifyContent: "center", alignItems: "center", gap: 10 }}>
                    <img style={{
                        animation: this.props.animation ? "start-button-arrow 0.8s infinite" : "none",
                        animationTimingFunction: "ease"
                    }}
                        src={this.props.icon}
                        alt="" />
                    <div className="button-label" style={{
                        fontSize: this.props.fontSize,
                        padding: "0.2em"
                    }}>
                        {this.props.label}
                    </div>

                </div>
            </div>
        )
    }
}

GenericButton.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.string,
    callback: PropTypes.func,
    height: PropTypes.string,
    width: PropTypes.string,
    fontSize: PropTypes.string,
    secondary: PropTypes.bool,
    animation: PropTypes.bool
};

GenericButton.defaultProps = {
    secondary: false,
    width: "100%",
    height: "auto",
    animation: false
};

export default GenericButton;
import React from "react";
import "./App.css";
import GamePage from "./components/pages/GamePage";
import packageJson from "../package.json";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = { toolbarHeight: 0 };
  }

  componentDidMount() {
    console.log(`Version : v${packageJson.version}`);
    console.log(
      "%cDesigned and developed by",
      "color: #0F8BD1; font-size: 24px"
    );
    console.log(
      "%c⏻aks %cSolution and Studio ",
      "color: #42E501; font-size: 32px; font-weight: 900",
      "color: #0F8BD1; font-size: 32px; font-weight: 900"
    );
    console.log(
      "%cContact us via: 📬 %cinquiry@the-oaks.my",
      "color: #04CFFF; font-size: 14px",
      "color: #0F8BD1; font-size: 16px; font-weight: 700"
    );
    console.log("------------------");
    console.log(
      "%cCredit:",
      "color: #42E501; font-size: 18px; font-weight: 700"
    );
    console.log(
      "%cLye %clye@the-oaks.my",
      "color: #04CFFF; font-size: 14px;",
      "color: #FF8BD1; font-size: 16px; font-weight: 700"
    );
    console.log(
      "%cChan %ckarhoong@the-oaks.my",
      "color: #04CFFF; font-size: 14px;",
      "color: #FF8BD1; font-size: 16px; font-weight: 700"
    );
    console.log(
      "%cWilliam Cheng %cwilliam@the-oaks.my",
      "color: #04CFFF; font-size: 14px;",
      "color: #FF8BD1; font-size: 16px; font-weight: 700"
    );

    this.setState({ toolbarHeight: window.innerHeight });
    window.addEventListener("resize", () => {
      this.setState({ toolbarHeight: window.innerHeight });
    });
  }

  render() {
    return (
      <div
        className="root-div"
        id="root-div"
        style={{
          height: `${this.state.toolbarHeight}px`,
        }}
      >
        <GamePage />
      </div>
    );
  }
}

export default App;
